@import "../colors.scss";

.div-subproceso {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 80px;
  background: $azul-3a;
  margin: 10px auto;
  border-radius: 10px;
  cursor: pointer;
  color: $gray-1;
  border: 2px solid black;
}

.nombre-subproceso {
  color: $azul-3a;
}

.cuadrito-subproceso {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.cuadrito {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  color: black;
}

.div-main-subproceso:hover {
  .div-subproceso {
    background: $gold-7;
    border: 2px solid $azul-3a;
  }
  .nombre-subproceso {
    color: $gold-7;
  }
  .cuadrito {
    border: 2px solid $azul-3a;
    color: $azul-3a;
  }
}

@media only screen and (max-width: 1000px) {
  .div-subproceso {
    width: 95%;
    font-size: small;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .div-subproceso {
    width: 90%;
    font-size: small;
  }
}
