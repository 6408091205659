@import "../colors.scss";

.container-dashboard {
  width: 100%;
  height: 100vh;
  text-align: center;
}

.container-dashboard .ant-row {
  justify-content: center;
}

.logo-dashboard {
  margin: 20px auto 0px auto;
  width: 40%;
}

.button-dashboard {
  // width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  text-align: center;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  border: unset;
  box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.8),
    1px 1px 5px rgba(0, 0, 0, 0.2);
  margin: auto;
  color: unset;
}

.button-dashboard:hover {
  opacity: 1;
  box-shadow: inset -4px -4px 8px rgba(255, 255, 255, 0.5),
    inset 8px 8px 16px rgba(0, 0, 0, 0.1);
  color: #000000;
  border: unset;
  color: unset;
}

.button-dashboard::before {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  text-align: center;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  border: unset;
  box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.8),
    1px 1px 5px rgba(0, 0, 0, 0.2);
  margin: auto;
  color: unset;
}

.button-dashboard::after {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  text-align: center;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  border: unset;
  box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.8),
    1px 1px 5px rgba(0, 0, 0, 0.2);
  margin: auto;
  color: unset;
}

.button-dashboard:active {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  text-align: center;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  border: unset;
  box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.8),
    1px 1px 5px rgba(0, 0, 0, 0.2);
  margin: auto;
  color: unset;
}

.button-dashboard:focus {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  text-align: center;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  border: unset;
  box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.8),
    1px 1px 5px rgba(0, 0, 0, 0.2);
  margin: auto;
  color: unset;
}

.col-content-dashboard {
  padding: 10px;
}

@media only screen and (max-width: 1000px) {
  .logo-dashboard {
    margin: 20px auto;
    width: 80%;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
}
