@import "../colors.scss";

.div-main-documento {
  border: 2px solid $gold-7;
  width: 80%;
  // min-height: 100px;
  // max-height: 100%;
  min-height: 100%;
  color: $azul-3a;
  cursor: pointer;
  margin: auto;
  border-radius: 20px;
}

.nombre-documento {
  border-bottom: 2px solid $gold-7;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 5px;
}

.detalle-documento {
  text-align: left;
  padding: 10px;
}

.footer-documento {
  font-weight: bold;
  text-align: left;
  padding: 10px;
}

.div-main-documento:hover {
  background: $gold-2;
}

.descargado {
  color: $green-7;
  font-size: large;
}

.no-descargado {
  color: $volcano-7;
  font-size: large;
}

@media only screen and (max-width: 1000px) {
  .div-main-documento {
    width: 95%;
    font-size: small;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .div-main-documento {
    width: 90%;
    font-size: small;
  }
}