@import "../colors.scss";

.div-area {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100px;
  border: 1px solid $azul-3a;
  margin: 10px auto;
  border-radius: 40px 0px 40px 0px;
  cursor: pointer;
  font-size: medium;
}

.div-area:hover {
  color: $gold-7;
  border: 1px solid $gold-7;
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .div-area {
    width: 90%;
  }
}
