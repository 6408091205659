@import "../colors.scss";

.div-proceso {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100px;
  background: $gold-7;
  margin: 10px auto;
  border-radius: 10px;
  cursor: pointer;
  font-size: large;
  font-weight: bold;
  color: $gray-1;
}

.div-proceso:hover {
  color: $gold-7;
  background: $azul-3a;
}

@media only screen and (max-width: 1000px) {
  .div-proceso {
    width: 95%;
    font-size: small;
  }
}

@media only screen and (max-width: 1000px) and (orientation: landscape) {
  .div-proceso {
    width: 90%;
    font-size: small;
  }
}
