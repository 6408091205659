@import "../colors.scss";

.ant-row {
  width: 100%;
}

input {
  width: 98% !important;
}

.ant-list-item {
  cursor: pointer;
}
