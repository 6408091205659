@import "../colors.scss";

.nombre-proceso-subprocesos {
  color: $azul-3a !important;
  font-weight: bold !important;
  margin: 15px auto !important;
}

.div-subprocesos {
  // border: 2px dashed $gold-7;
  width: 100%;
  padding: 10px;
  margin: 10px;
}
