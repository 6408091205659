@import "./fonts.scss";
@import "./colors.scss";

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: $azul-3a;
}
::-webkit-scrollbar-track {
  background: $amarillo-3a;
}

body {
  font-family: $Quicksand;
  font-weight: bold;
}

// CLASES GENERALES
.mb-50 {
  margin-bottom: 50px !important;
}

/** =============================================================================================== */
/** =========================================== INPUTS =========================================== */
/** =============================================================================================== */

textarea,
button,
.ant-input,
.ant-input-password,
.ant-input-affix-wrapper,
.ant-select-selector,
.ant-picker {
  border-radius: 10px !important;
  text-align: center;
  resize: none;
}

.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

textarea:focus,
button:focus,
.ant-input:focus,
.ant-input-password:focus,
.ant-input-affix-wrapper:focus,
.ant-select-selector:focus,
.ant-picker:focus {
  border-radius: 10px !important;
  text-align: center;
  resize: none;
  box-shadow: none;
}

// ICONS
.icon-pdf {
  color: $volcano-7;
  cursor: pointer;
  font-size: large;
}

.icon-word {
  color: $blue-7;
  cursor: pointer;
  font-size: large;
}

.icon-excel {
  color: $green-7;
  cursor: pointer;
  font-size: large;
}

.icon-imagen {
  color: $yellow-7;
  cursor: pointer;
  font-size: large;
}

// PULSE
.notificacion {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background: $gold-7;
  transform: scale(1);
  animation: pulse 1s infinite;
  box-shadow: 0 0 0 0 $gold-2;
  color: #ffffff;
  font-size: x-small;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $gold-2;
  }

  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px $gold-2;
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $gold-2;
  }
}

/** =============================================================================================== */
/** =========================================== BOTONES =========================================== */
/** =============================================================================================== */

// BUTTON SUCCESS
.button-success {
  background-color: $green-7;
  border: 1px solid $green-7;
  color: $gray-1;
}

.button-success:hover {
  background-color: $green-7;
  border: 1px solid $green-7;
  color: $gray-1;
  opacity: 0.8;
}

.button-success::after {
  background-color: $green-7;
  border: 1px solid $green-7;
  color: $gray-1;
}

.button-success::before {
  background-color: $green-7;
  border: 1px solid $green-7;
  color: $gray-1;
}

.button-success:focus {
  background-color: $green-7;
  border: 1px solid $green-7;
  color: $gray-1;
}

.button-success:active {
  background-color: $green-7;
  border: 1px solid $green-7;
  color: $gray-1;
}

// BUTTON AMARILLO
.button-yellow {
  background-color: $gold-7;
  border: 1px solid $gold-7;
  color: $gray-1;
}

.button-yellow:hover {
  background-color: $gold-7;
  border: 1px solid $gold-7;
  color: $gray-1;
  opacity: 0.8;
}

.button-yellow::after {
  background-color: $gold-7;
  border: 1px solid $gold-7;
  color: $gray-1;
}

.button-yellow::before {
  background-color: $gold-7;
  border: 1px solid $gold-7;
  color: $gray-1;
}

.button-yellow:focus {
  background-color: $gold-7;
  border: 1px solid $gold-7;
  color: $gray-1;
}

.button-yellow:active {
  background-color: $gold-7;
  border: 1px solid $gold-7;
  color: $gray-1;
}

// BUTTON DANGER
.button-danger {
  background-color: $red-7;
  border: 1px solid $red-7;
  color: $gray-1;
}

.button-danger:hover {
  background-color: $red-7;
  border: 1px solid $red-7;
  color: $gray-1;
  opacity: 0.8;
}

.button-danger::after {
  background-color: $red-7;
  border: 1px solid $red-7;
  color: $gray-1;
}

.button-danger::before {
  background-color: $red-7;
  border: 1px solid $red-7;
  color: $gray-1;
}

.button-danger:focus {
  background-color: $red-7;
  border: 1px solid $red-7;
  color: $gray-1;
}

.button-danger:active {
  background-color: $red-7;
  border: 1px solid $red-7;
  color: $gray-1;
}

// BUTTON WARNING
.button-warning {
  background-color: $volcano-7;
  border: 1px solid $volcano-7;
  color: $gray-1;
}

.button-warning:hover {
  background-color: $volcano-7;
  border: 1px solid $volcano-7;
  color: $gray-1;
  opacity: 0.8;
}

.button-warning::after {
  background-color: $volcano-7;
  border: 1px solid $volcano-7;
  color: $gray-1;
}

.button-warning::before {
  background-color: $volcano-7;
  border: 1px solid $volcano-7;
  color: $gray-1;
}

.button-warning:focus {
  background-color: $volcano-7;
  border: 1px solid $volcano-7;
  color: $gray-1;
}

.button-warning:active {
  background-color: $volcano-7;
  border: 1px solid $volcano-7;
  color: $gray-1;
}

// BUTTON INFO
.button-info {
  background-color: $blue-6;
  border: 1px solid $blue-6;
  color: $gray-1;
}

.button-info:hover {
  background-color: $blue-6;
  border: 1px solid $blue-6;
  color: $gray-1;
  opacity: 0.8;
}

.button-info::after {
  background-color: $blue-6;
  border: 1px solid $blue-6;
  color: $gray-1;
}

.button-info::before {
  background-color: $blue-6;
  border: 1px solid $blue-6;
  color: $gray-1;
}

.button-info:focus {
  background-color: $blue-6;
  border: 1px solid $blue-6;
  color: $gray-1;
}

.button-info:active {
  background-color: $blue-6;
  border: 1px solid $blue-6;
  color: $gray-1;
}

/** =============================================================================================== */
/** =========================================== SPIN =========================================== */
/** =============================================================================================== */

// SUCESS
.spin-success i {
  background: $green-7;
}

.spin-success .ant-spin-text {
  color: $green-7;
}

// YELLOW
.spin-yellow i {
  background: $gold-7;
}

.spin-yellow .ant-spin-text {
  color: $gold-7;
}

// DANGER
.spin-danger i {
  background: $red-7;
}

.spin-danger .ant-spin-text {
  color: $red-7;
}

// INFO
.spin-info i {
  background: $blue-6;
}

.spin-info .ant-spin-text {
  color: $blue-6;
}

// WARNING
.spin-warning i {
  background: $volcano-7;
}

.spin-warning .ant-spin-text {
  color: $volcano-7;
}
